import React, { PureComponent, createRef } from "react";
import fetch from "node-fetch";

import ContactForm from "../components/ContactForm/ContactForm";
import ContactWrapper from "../components/ContactWrapper/ContactWrapper";
import ContactMap from "../components/ContactMap/ContactMap";
import ContactFormSuccess from "../components/ContactFormSuccess/ContactFormSuccess";

function encode(data) {
	return Object.keys(data)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
		.join("&");
}

export default class Contact extends PureComponent {
	contactFormRef = createRef();

	state = {
		formSuccess: false,
		formLoading: false,
	};

	onSubmit = async (data) => {
		try {
			await fetch("/", {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: encode(data),
			}).then((res) => {
				this.setState({
					formLoading: false,
					formSuccess: true,
				});
			});
		} catch (e) {
			this.setState({
				formLoading: false,
			});
		}
	};

	onSuccessAccept = () => {
		this.contactFormRef.current.resetForm();
		this.setState({
			formSuccess: false,
		});
	};

	render() {
		const { formSuccess, formLoading } = this.state;

		return (
			<ContactWrapper>
				<ContactForm
					ref={this.contactFormRef}
					onSubmit={this.onSubmit}
					success={formSuccess}
					loading={formLoading}
				/>
				<ContactMap />
				{formSuccess ? <ContactFormSuccess onSuccessAccept={this.onSuccessAccept} /> : null}
			</ContactWrapper>
		);
	}
}
