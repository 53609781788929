import React from "react";
import PropTypes from "prop-types";

import styles from "./ContactFormSuccess.module.scss";
import Button from "../Button/Button";

const propTypes = {};

function ContactFormSuccess({ onSuccessAccept }) {
	return (
		<section className={styles.wrapper__main}>
			<div className={styles.overlay} />
			<section className={styles.window__wrapperMain}>
				<img
					className={styles.window__visual}
					src={"/img/success-visual.jpg"}
					srcSet={"/img/success-visual.jpg 1x, /img/success-visual@2x.jpg 2x"}
					alt="success visual"
				/>

				<div className={styles.window__wrapperContent}>
					<h1 className={styles.window__header}>Ok!</h1>
					<p className={styles.window__body}>
						Request sent! Please give us 1-3 working days to get back to you!
					</p>
					<Button
						element="div"
						onClick={onSuccessAccept}
						colorScheme="black"
						text="OK"
						className={styles.window__button}
					/>
				</div>
			</section>
		</section>
	);
}

ContactFormSuccess.propTypes = propTypes;

export default ContactFormSuccess;
