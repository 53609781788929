import React from "react";
import PropTypes from "prop-types";

import styles from "./ContactWrapper.module.scss";

const propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

function ContactWrapper({ children }) {
	return <section className={styles.wrapper__main}>{children}</section>;
}

ContactWrapper.propTypes = propTypes;

export default ContactWrapper;
