import React, { useState, useCallback } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";

const propTypes = {
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
};

function MapModule({ lat, lng }) {
	const [map, setMap] = useState(null);

	const onLoad = useCallback(function callback(map) {
		const bounds = new window.google.maps.LatLngBounds();
		map.fitBounds(bounds);
		setMap(map);
	}, []);

	const onUnmount = useCallback(function callback(map) {
		setMap(null);
	}, []);

	return (
		<LoadScript googleMapsApiKey="AIzaSyAjQGroDjjXizjfkRIiOS6HrzuszsnX5q0">
			<GoogleMap
				mapContainerStyle={{
					width: "100%",
					height: "100%",
					position: "absolute",
				}}
				center={{
					lat,
					lng,
				}}
				zoom={15}
				onUnmount={onUnmount}
				options={{
					styles: [
						{
							elementType: "geometry",
							stylers: [
								{
									color: "#212121",
								},
							],
						},
						{
							elementType: "labels.icon",
							stylers: [
								{
									visibility: "off",
								},
							],
						},
						{
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#757575",
								},
							],
						},
						{
							elementType: "labels.text.stroke",
							stylers: [
								{
									color: "#212121",
								},
							],
						},
						{
							featureType: "administrative",
							elementType: "geometry",
							stylers: [
								{
									color: "#757575",
								},
							],
						},
						{
							featureType: "administrative.country",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#9e9e9e",
								},
							],
						},
						{
							featureType: "administrative.land_parcel",
							stylers: [
								{
									visibility: "off",
								},
							],
						},
						{
							featureType: "administrative.locality",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#bdbdbd",
								},
							],
						},
						{
							featureType: "poi",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#757575",
								},
							],
						},
						{
							featureType: "poi.park",
							elementType: "geometry",
							stylers: [
								{
									color: "#181818",
								},
							],
						},
						{
							featureType: "poi.park",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#616161",
								},
							],
						},
						{
							featureType: "poi.park",
							elementType: "labels.text.stroke",
							stylers: [
								{
									color: "#1b1b1b",
								},
							],
						},
						{
							featureType: "road",
							elementType: "geometry.fill",
							stylers: [
								{
									color: "#2c2c2c",
								},
							],
						},
						{
							featureType: "road",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#8a8a8a",
								},
							],
						},
						{
							featureType: "road.arterial",
							elementType: "geometry",
							stylers: [
								{
									color: "#373737",
								},
							],
						},
						{
							featureType: "road.highway",
							elementType: "geometry",
							stylers: [
								{
									color: "#3c3c3c",
								},
							],
						},
						{
							featureType: "road.highway.controlled_access",
							elementType: "geometry",
							stylers: [
								{
									color: "#4e4e4e",
								},
							],
						},
						{
							featureType: "road.local",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#616161",
								},
							],
						},
						{
							featureType: "transit",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#757575",
								},
							],
						},
						{
							featureType: "water",
							elementType: "geometry",
							stylers: [
								{
									color: "#000000",
								},
							],
						},
						{
							featureType: "water",
							elementType: "labels.text.fill",
							stylers: [
								{
									color: "#3d3d3d",
								},
							],
						},
					],
				}}
			>
				<Marker position={{ lat, lng }} animation={4} />
			</GoogleMap>
		</LoadScript>
	);
}

MapModule.propTypes = propTypes;
export default React.memo(MapModule);
