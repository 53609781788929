import React from "react";
import { StaticQuery, graphql } from "gatsby";
import uniqid from "uniqid";
import Obfuscate from "react-obfuscate";

import styles from "./ContactMap.module.scss";
import { formatLineBreak } from "../../utils";
import { useState } from "react";
import MapModule from "./MapModule";

function ContactMap() {
	return (
		<StaticQuery
			query={graphql`
				query {
					data: allFile(filter: { name: { eq: "contact" } }) {
						edges {
							node {
								childMarkdownRemark {
									id
									frontmatter {
										address
										phone
										email
										mapCoords
									}
								}
							}
						}
					}
				}
			`}
			render={({ data }) => {
				const {
					edges: [
						{
							node: {
								childMarkdownRemark: {
									frontmatter: { address, phone, email, mapCoords },
								},
							},
						},
					],
				} = data;

				return (
					<section className={styles.wrapper__main}>
						<section className={styles.info__wrapper}>
							<div className={styles.info__col}>
								<h2 className={styles.info__title}>Address</h2>
								<div>
									{formatLineBreak(address).map((i) => (
										<p key={uniqid()} className={styles.info__body}>
											{i}
										</p>
									))}
								</div>
							</div>
							<div className={styles.info__col}>
								<h2 className={styles.info__title}>Contact</h2>
								<p className={styles.info__body}>
									<Obfuscate tel={phone} />
								</p>
								<p className={styles.info__body}>
									<Obfuscate email={email} />
								</p>
							</div>
						</section>

						<div className={styles.wrapper__map}>
							<MapModule
								lat={JSON.parse(mapCoords).coordinates[1]}
								lng={JSON.parse(mapCoords).coordinates[0]}
							/>
						</div>
					</section>
				);
			}}
		/>
	);
}

export default ContactMap;
