import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Button from "../Button/Button";

import styles from "./ContactForm.module.scss";

const propTypes = {
	onSubmit: PropTypes.func,
	success: PropTypes.bool,
	loading: PropTypes.bool,
};

const defaultProps = {
	onSubmit: () => {},
	success: false,
	loading: false,
};

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Name is too short!")
		.max(80, "Name is too long!")
		.required("Name is required."),
	company: Yup.string(),
	email: Yup.string().email("Invalid Email").required("Email is required."),
	message: Yup.string().min(2, "Message is too short").required("Message is required."),
});

const ContactForm = React.forwardRef(function ({ onSubmit, success, loading }, ref) {
	return (
		<section className={styles.wrapper__main}>
			<h1 className={styles.formTitle}>
				Hello there,
				<br />
				tell us what you want to do —
			</h1>
			<Formik
				innerRef={ref}
				initialValues={{
					"form-name": "contact",
					name: "",
					company: "",
					email: "",
					message: "",
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ errors, touched }) => {
					return (
						<Form name="contact" data-netlify={true}>
							<h2 className={styles.form__header}>How do we get in touch</h2>
							<div>
								<Field
									name="name"
									className={`${styles.form__inputBox} ${
										errors.name ? styles["form--error"] : null
									}`}
									placeholder="Name*"
								/>
							</div>
							<div>
								<Field name="company" className={styles.form__inputBox} placeholder="Company" />
							</div>
							<div>
								<Field
									name="email"
									className={`${styles.form__inputBox} ${
										errors.email ? styles["form--error"] : null
									}`}
									placeholder="Email Address*"
								/>
							</div>
							<h2 className={styles.form__header}>Your Message</h2>
							<div>
								<Field
									name="message"
									as="textarea"
									className={`${styles.form__inputBox} ${styles.form__textarea} ${
										errors.message ? styles["form--error"] : null
									}`}
									placeholder="Let us know your enquiry, do feel free to list down everything. The more info we have the more we can understand what you need."
								/>
							</div>
							<Field type="hidden" name="bot-field" />
							<Field type="hidden" name="form-name" />
							{errors.name && touched.name ? (
								<div className={styles.form__errorMsg}>{errors.name}</div>
							) : null}
							{errors.company && touched.company ? (
								<div className={styles.form__errorMsg}>{errors.company}</div>
							) : null}
							{errors.email && touched.email ? (
								<div className={styles.form__errorMsg}>{errors.email}</div>
							) : null}
							{errors.message && touched.message ? (
								<div className={styles.form__errorMsg}>{errors.message}</div>
							) : null}
							<Button
								element="button"
								type="submit"
								colorScheme="black"
								text="SUBMIT"
								className={styles.form__button}
								loading={loading}
							/>
						</Form>
					);
				}}
			</Formik>
		</section>
	);
});

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
